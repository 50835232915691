// eslint doesn't seem to play nicely with the webpack aliases
// eslint-disable-next-line import/no-unresolved
import { capabilities, env } from 'app-config';


function ensureSingleTrailingSlash(url) {
    return `${url}/`.replace(/(\/){2,}$/, '/');
}

/**
 * Returns the Real service url (not proxied)
 * @param {string} serviceName the name of the service to invoke
 *                  (the service name is defined in the ServiceEnpoint of each capability)
 * @returns {string} the real service url
 */
export function getProxiedServiceUrl(serviceName) {
    const endpoint = capabilities[serviceName];
    const baseUrl = ensureSingleTrailingSlash(env.DEPLOYMENT_URL);
    return baseUrl + endpoint?.service;
}
export function getBCProxiedServiceUrl(serviceName) {
    //const endpoint = capabilities[serviceName];
    //const baseUrl = "https://bateai.wsib.ca/";
    console.log("in URL");
    const endpoint = "external/profileinfo/statement";
    const baseUrl = ensureSingleTrailingSlash(env.DEPLOYMENT_URL);
    console.log(endpoint);
    console.log(baseUrl + endpoint?.service);
    const newendpoint = baseUrl + "external/profileinfo/statement";
    return newendpoint;
}


/**
 * Returns a url which will be proxied depending on the base url
 *
 * This is generally meant for *non-EDGE* URLs.
 * If you you want to proxy an edge capability, you should probably use
 * `getProxiedServiceUrl`
 *
 * @see {@link getProxiedServiceUrl}
 * @param {string} urlToProxy the url that should be proxied
 * @returns {string} the real url which accounts for the deployment url
 */
export function getProxiedUrl(urlToProxy) {
    const baseUrl = ensureSingleTrailingSlash(env.DEPLOYMENT_URL);
    return baseUrl + urlToProxy;
}

export function getProxyURLforDocumentDetail(serviceName, document) {
    const endpoint = capabilities[serviceName];
//     const baseUrl = ensureSingleTrailingSlash(env.DEPLOYMENT_URL);
    const baseUrl = ensureSingleTrailingSlash(env.DEPLOYMENT_URL);
    return `${baseUrl}${endpoint?.service}/${document.publicID}/?token=${document.sessionID}&target=_blank`;
}
