/* eslint-disable prefer-destructuring */
/* eslint-disable radix */
/* eslint-disable use-isnan */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-mixed-operators */
/* eslint-disable valid-jsdoc */
import { ModalNextProvider } from '@jutro/components';
import { publish } from '@jutro/events';
import React from 'react';
import Configuration from '../key-configuration/configuration.json5';

// guidewire authentation
export default class UtilService {
    /**
   *
   * @param {any} value
   * @returns true
   * function to check given value is undefined , null, NaN, blank
   */
    static isEmptyValues(value) {
        if (value === '{' || value === '}' || value === '{}') {
            value = null;
        }
        // adding comments
        // eslint-disable-next-line use-isnan
        return (
            value === undefined
      || value === null
      || (typeof value === 'object' && Object.keys(value).length === 0)
      || (typeof value === 'string' && value.trim().length === 0)
        );
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return file a claim link on the basis of key and language parameters.
   */
    static doaFormLink(lang) {
        if (lang === 'fr') {
            return Configuration.doaFormLink.fr;
        }
        return Configuration.doaFormLink.en;
    }

    /**
   *
   * @param {string} value
   * @returns false
   * function to check given date is valid or not
   * when date is invalid it return true otherwise false.
   */
    static isValidDate(value) {
        const date = new Date(value);
        return !(date instanceof Date && !isNaN(date.valueOf()));
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return footer link on the basis of key and language parameters.
   */
    static footerLink(key, lang) {
        if (lang === 'fr') {
            return Configuration.contentFrench[key];
        }
        return Configuration.contentEnglish[key];
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return footer link on the basis of key and language parameters.
   */
    static reportPremium(key, lang) {
        if (lang === 'fr') {
            return Configuration.contentFrench[key];
        }
        return Configuration.contentEnglish[key];
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return footer link on the basis of key and language parameters.
   */
    static registerLink(key, lang) {
        if (lang === 'fr') {
            return Configuration.contentFrench[key];
        }
        return Configuration.contentEnglish[key];
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return footer link on the basis of key and language parameters.
   */
    static independentLink(key, lang) {
        if (lang === 'fr') {
            return Configuration.contentFrench[key];
        }
        return Configuration.contentEnglish[key];
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return profile link on the basis of key and language parameters.
   */
    static profileLink(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(Configuration.profileLink.fr);
        }
        return this.HandleAmpercend(Configuration.profileLink.en);
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return business link on the basis of key and language parameters.
   */
    static businessLink(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(Configuration.businessLink.fr);
        }
        return this.HandleAmpercend(Configuration.businessLink.en);
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return unified logout link on the basis of key and language parameters.
   */
    static unifiedLogout(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(Configuration.unifiedLogout.fr);
        }
        return this.HandleAmpercend(Configuration.unifiedLogout.en);
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return ill link on the basis of key and language parameters.
   */
    static illOrInjured(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(Configuration.illOrInjured.fr);
        }
        return this.HandleAmpercend(Configuration.illOrInjured.en);
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return file a claim link on the basis of key and language parameters.
   */
    static faq(lang) {
        if (lang === 'fr') {
            return Configuration.faq.fr;
        }
        return Configuration.faq.en;
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return unified logout session expired link on the basis of key and language parameters.
   */
    static unifiedLogoutSessionExpired(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(Configuration.unifiedLogoutSessionExpired.fr);
        }
        return this.HandleAmpercend(Configuration.unifiedLogoutSessionExpired.en);
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return online services link link on the basis of key and language parameters.
   */
    static onlineSerices(lang) {
        if (lang === 'fr') {
            return this.HandleAmpercend(Configuration.onlineSerices.fr);
        }
        return this.HandleAmpercend(Configuration.onlineSerices.en);
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return helpful link on the basis of key and language parameters.
   */
    static accountHelpfulLinks(key, lang) {
        if (lang === 'fr') {
            return Configuration.contentFrench[key];
        }
        return Configuration.contentEnglish[key];
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return footer link on the basis of key and language parameters.
   */
    static fetchAriaLabel(key, lang) {
        if (lang === 'fr') {
            return Configuration.ariaLabelFrench[key];
        }
        return Configuration.ariaLabelEnglish[key];
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return title label on the basis of key and language parameters.
   */
    static fetchTitleLabel(key, lang) {
        if (lang === 'fr') {
            return Configuration.titleLabelFrench[key];
        }
        return Configuration.titleLabelEnglish[key];
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return title label on the basis of key and language parameters.
   */
  static fetchTitleLabel(key, lang) {
    if (lang === "fr") {
      return Configuration.titleLabelFrench[key];
    }
    return Configuration.titleLabelEnglish[key];
  }

  /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return GA tags based on key
   */
    static fetchGaTag(key, lang) {
        if (lang === 'fr') {
            return Configuration.ga_fr[key];
        }
        return Configuration.ga_en[key];
    }

    /**
   * @param {string} URL
   * @returns link
   * replace {AND} with &
   */
    static HandleAmpercend(value) {
        return value.toString().replace(/{AND}/g, '&');
    }

    // Appending aria-labels for OOTB  Data-table pagination
    static setPaginationAriaLabels(tableID) {
        const pagination = [];
        const links = document.querySelectorAll(
            `${tableID} .jut__Pagination__pageButtonsWrapper .jut__Pagination__paginationMenuItem`
        );
        const buttons = document.querySelectorAll(
            `${tableID} .jut__Pagination__pageButtonsWrapper .jut__Pagination__paginationNavButton`
        );
        const collapsedButtons = document.querySelectorAll(
            `${tableID} .jut__Pagination__pageButtonsWrapper .jut__DropdownMenu__dropdownMenu button`
        );
        const resultsButton = document.querySelectorAll(
            `${tableID} .jut__Pagination__pageSizeSelectWrapper .jut__DropdownMenu__dropdownMenu button`
        );
        const resultsPerPageText = document.querySelectorAll(
            `${tableID} .jut__Pagination__pageSizeSelectWrapper .jut__Pagination__resultOfMessage`
        );
        resultsPerPageText.forEach((text) => {
            text.setAttribute('role', 'status');
        });
        resultsButton.forEach((button) => {
            button.setAttribute('aria-label', `${button.innerText} Results Per Page`);
        });
        links.forEach((link) => {
            pagination.push({ text: +link.innerText, target: link });
        });
        buttons.forEach((button) => {
            pagination.push({ text: +button.innerText, target: button });
        });

        // Page buttons
        const pageButtons = pagination
            .filter((pageLink) => {
                return pageLink.target.type === 'button';
            })
            .sort((a, b) => a.text - b.text);

        const buttonCount = pageButtons.length;
        let prevPageButton = 0;

        pageButtons.forEach((pb, index) => {
            const button = pb.target;
            const bIndex = index + 1;
            button.setAttribute(
                'aria-label',
                `Button ${bIndex} of ${buttonCount}, Page ${pb.text}`
            );

            // Page dropdown Links
            if (pb.text - prevPageButton > 1) {
                const diff = pb.text - prevPageButton - 1;

                let linkCtr = prevPageButton + 1;
                do {
                    const a = () => {
                        const link = pagination.find((page) => {
                            return page.target.type !== 'button' && page.text === linkCtr;
                        });

                        const text = linkCtr - prevPageButton;
                        link.target.setAttribute(
                            'aria-label',
                            `Dropdown item ${text} of ${diff}, Page ${link.text}`
                        );
                        return true;
                    };
                    a();
                    linkCtr += 1;
                } while (linkCtr < pb.text);
            }
            prevPageButton = pb.text;
        });

        // Aria-Labelling Expandable buttons
        collapsedButtons.forEach((link) => {
            link.setAttribute('aria-label', 'Expand for more options');
        });
    }

    /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return claim form link on the basis of key and language parameters.
   */
    static claimForm(lang) {
        if (lang === 'fr') {
            return Configuration.claimForm.fr;
        }
        return Configuration.claimForm.en;
    }

    /**
   *
   * @param {string} publishEvent
   * @param {string} publishCategory
   * @param {string} lang
   * Publish GA tags
   */

    static publishGA(publishEvent, publishCategory, lang) {
        publish(publishEvent, {
            eventCategory: this.fetchGaTag(publishCategory, lang),
            locale: this.fetchGaTag(publishCategory, lang)
        });
    }

  /*
   * @param {string} date
   * @returns date
   * get content on the basis of key
   */
  static customDateFormatMailView = (date, lang) => {
      try {
          const day = date.toString().split(' ')[0];
          const p = new Intl.DateTimeFormat(lang, {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
          })
              .formatToParts(new Date(date))
              .reduce((acc, part) => {
                  acc[part.type] = part.value;
                  return acc;
              }, {});
          let returnDate = `${p.day} ${p.month} ${p.year}`;
          if (lang === 'EN' || lang === 'en' || lang === 'en-US') {
              if (p.month.toLowerCase() === 'jun') {
                  returnDate = `${p.day} June ${p.year}`;
              } else if (p.month.toLowerCase() === 'jul') {
                  returnDate = `${p.day} July ${p.year}`;
              } else if (p.month.toLowerCase() === 'sep') {
                  returnDate = `${p.day} Sept ${p.year}`;
              }
          }
          else if (lang === 'fr') {
              if (p.month.toLowerCase() === 'oct.') {
                  returnDate = `${p.day} OCT ${p.year}`;
              } else if (p.month.toLowerCase() === 'avr.') {
                  returnDate = `${p.day} AVR ${p.year}`;
              } else if (p.month.toLowerCase() === 'nov.') {
                  returnDate = `${p.day} NOV ${p.year}`;
              } else if (p.month.toLowerCase() === 'déc.') {
                  returnDate = `${p.day} DÉC ${p.year}`;
              }
              else {
                  returnDate = `${p.day} ${p.month
                      .substring(0, 4)
                      .toUpperCase()} ${p.year}`;
              }
          }
          return returnDate;
      } catch (error) {
      // eslint-disable-next-line no-console
          console.log(error);
          return '';
      }
  };
    
   static reportLink(lang) {
      if (lang === 'fr') {
          return this.HandleAmpercend(Configuration.contentFrench.reportLink);
      }
      return this.HandleAmpercend(Configuration.contentEnglish.reportLink);
  }

  /*
   * @param {string} date
   * @returns date
   * get content on the basis of key
   */
  static customDateFormat = (date, lang) => {
      try {
          let day = date.toString().split('T')[0];
          day = day.split('-')[2];
          const p = new Intl.DateTimeFormat(lang, {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
          })
              .formatToParts(new Date(date))
              .reduce((acc, part) => {
                  acc[part.type] = part.value;
                  return acc;
              }, {});
          let returnDate = `${p.day} ${p.month} ${p.year}`;
          if (lang === 'EN' || lang === 'en' || lang === 'en-US') {
              if (p.month.toLowerCase() === 'jun') {
                  returnDate = `${p.day} June ${p.year}`;
              } else if (p.month.toLowerCase() === 'jul') {
                  returnDate = `${p.day} July ${p.year}`;
              } else if (p.month.toLowerCase() === 'sep') {
                  returnDate = `${p.day} Sept ${p.year}`;
              }
          }
          else if (lang === 'fr') {
              if (p.month.toLowerCase() === 'oct.') {
                  returnDate = `${p.day} OCT ${p.year}`;
              } else if (p.month.toLowerCase() === 'avr.') {
                  returnDate = `${p.day} AVR ${p.year}`;
              } else if (p.month.toLowerCase() === 'nov.') {
                  returnDate = `${p.day} NOV ${p.year}`;
              } else if (p.month.toLowerCase() === 'déc.') {
                  returnDate = `${p.day} DÉC ${p.year}`;
              }
              else {
                  returnDate = `${p.day} ${p.month
                      .substring(0, 4)
                      .toUpperCase()} ${p.year}`;
              }
          }
          return returnDate;
      } catch (error) {
      // eslint-disable-next-line no-console
          console.log(error);
          return '';
      }
  };

 /*
   * @param {string} date
   * @returns date
   * get content on the basis of key
   */
 static customDateFormatForAccount = (date, lang) => {
     try {
         let day = date.toString().split('T')[0];
         day = day.split('-')[2];
         const p = new Intl.DateTimeFormat(lang, {
             day: 'numeric',
             month: 'short',
             year: 'numeric',
             hour: '2-digit',
             minute: '2-digit',
             hour12: true
         })
             .formatToParts(new Date(date))
             .reduce((acc, part) => {
                 acc[part.type] = part.value;
                 return acc;
             }, {});
         let returnDate = `${p.day} ${p.month} ${p.year}`;
         if (lang === 'EN' || lang === 'en' || lang === 'en-US') {
             if (p.month.toLowerCase() === 'jun') {
                 returnDate = `${p.day} June ${p.year}`;
             } else if (p.month.toLowerCase() === 'jul') {
                 returnDate = `${p.day} July ${p.year}`;
             } else if (p.month.toLowerCase() === 'sep') {
                 returnDate = `${p.day} Sept ${p.year}`;
             }
         }
         else if (lang === 'fr') {
             if (p.month.toLowerCase() === 'oct.') {
                 returnDate = `${p.day} OCT ${p.year}`;
             } else if (p.month.toLowerCase() === 'avr.') {
                 returnDate = `${p.day} AVR ${p.year}`;
             } else if (p.month.toLowerCase() === 'nov.') {
                 returnDate = `${p.day} NOV ${p.year}`;
             } else if (p.month.toLowerCase() === 'déc.') {
                 returnDate = `${p.day} DÉC ${p.year}`;
             }
             else {
                 returnDate = `${p.day} ${p.month
                     .substring(0, 4)
                     .toUpperCase()} ${p.year}`;
             }
         }
         return returnDate;
     } catch (error) {
         // eslint-disable-next-line no-console
         console.log(error);
         return '';
     }
 };

  static customDateFormatAppeal = (date, lang) => {
      try {
          let day = date.toString().split('T')[0];
          day = day.split('-')[2];
          const p = new Intl.DateTimeFormat(lang, {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
          })
              .formatToParts(new Date(date))
              .reduce((acc, part) => {
                  acc[part.type] = part.value;
                  return acc;
              }, {});
          let returnDate = `${p.day} ${p.month} ${p.year}`;
          if (lang === 'EN' || lang === 'en' || lang === 'en-US') {
              if (p.month.toLowerCase() === 'jun') {
                  returnDate = `${p.day} June ${p.year}`;
              } else if (p.month.toLowerCase() === 'jul') {
                  returnDate = `${p.day} July ${p.year}`;
              } else if (p.month.toLowerCase() === 'sep') {
                  returnDate = `${p.day} Sept ${p.year}`;
              }
          }
          else if (lang === 'fr') {
              if (p.month.toLowerCase() === 'oct.') {
                  returnDate = `${p.day} OCT ${p.year}`;
              } else if (p.month.toLowerCase() === 'avr.') {
                  returnDate = `${p.day} AVR ${p.year}`;
              } else if (p.month.toLowerCase() === 'nov.') {
                  returnDate = `${p.day} NOV ${p.year}`;
              } else if (p.month.toLowerCase() === 'déc.') {
                  returnDate = `${p.day} DÉC ${p.year}`;
              }
              else {
                  returnDate = `${p.day} ${p.month
                      .substring(0, 4)
                      .toUpperCase()} ${p.year}`;
              }
          }
          return returnDate;
      } catch (error) {
      // eslint-disable-next-line no-console
          console.log(error);
          return '';
      }
  };

  /*
   * @param {string} lang
   * redirect if user logged
   */
  static checkIfUserLoggedIn = (lang) => {
      if (document.cookie.indexOf('Current-User=') === -1) {
          window.location.href = this.unifiedLogout(lang);
      }
  };

  /*
   * @param {string} lang
   * @param {number} amt
   * convert currency to Canadian Format
   */
  static currencyFormatting = (lang, amount) => {
      const amt = String(amount).replace('$', '');
      if (lang === 'fr') {
          return parseFloat(amt, 10).toLocaleString('fr-CA', { currency: 'CAD', style: 'currency' });
      }
      return parseFloat(amt, 10).toLocaleString('en-CA', { currency: 'CAD', style: 'currency' });
  };

  static checkSubset = (parentArray, subsetArray) => {
      return subsetArray.every((el) => {
          return parentArray.includes(el);
      });
  }

  /*
   * @param {object} authority
   * redirect if user role is changed
   */
  static checkUserRole = (authority) => {
      const matchRole = new Promise((reject, resolve) => {
          const currentUserRole = this.getUserRole();
          console.log('this.previousRole in check user', this.previousRole);
          console.log('currentUserRole in check user', currentUserRole);
          if (this.previousRole !== currentUserRole) {
              resolve(true);
          } else {
              reject(false);
          }
      });
      return matchRole;
  };


  /*
   * awaiting modal popup using for various purposes
   */
  static navigateAway = async (MSG, cancel, ok) => {
      const splittedMsg = MSG.split(':');
      const displayMessage = (
          <div>
              <h2>{`${splittedMsg[0]}`}</h2>
              <p>
                  {splittedMsg[1] ? `${splittedMsg[1]}` : ''}
                  {splittedMsg[2] || ''}
              </p>
          </div>
      );
      const results = await ModalNextProvider.showConfirm({
          status: 'warning',
          icon: 'mi-error-outline',
          confirmText: 'Confirmation',
          message: displayMessage,
          cancelButtonText: ok,
          confirmButtonText: cancel,
      });
      return new Promise((resolve, reject) => {
          if (results !== 'confirm') {
              sessionStorage.removeItem('documentAdded');
              resolve(true);
          }
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(false);
      });
  };


  /*
   *
   * getMessageType for message
   */
  static getMessageType = () => {
      return Configuration.MessageType;
  };

  /*
   * @param {string} originalMessage
   * @param {string} params
   * @param {string} specialChars
   * return originalMessage with replacement of special characters.
   */
  static encodeMessage = (originalMessage, params, specialChars) => {
      let encodedMessage = originalMessage;
      const encodedList = params.split(',');
      // eslint-disable-next-line no-restricted-syntax
      for (const encode of encodedList) {
          encodedMessage = originalMessage.replace(
              new RegExp(`\\b${encode.split(':')[0]}\\b`, 'g'),
              encode.split(':')[1]
          );
          // eslint-disable-next-line no-param-reassign
          originalMessage = encodedMessage;
      }
      const specialCharList = specialChars.split(';');
      // eslint-disable-next-line no-restricted-syntax
      for (const specChar of specialCharList) {
          encodedMessage = originalMessage.replace(
              new RegExp(specChar.split(':')[0], 'g'),
              specChar.split(':')[1]
          );
          originalMessage = encodedMessage;
      }
      return originalMessage;
  };

  /*
   * @param {array} roles
   * return looged in user role
   */
  static getUserRole = (role) => {
      let roles = document
          .getElementsByTagName('html')[0]
          .getAttribute('accesToken');
      const authorities = [];
      roles = roles.split(' ');
      roles.forEach((data) => {
          authorities.push(data.toLowerCase());
      });

      if (
          authorities.indexOf('guidewire.edge.wsib_online_service.osbc.admin_and_user_role') !== -1
          || (authorities.indexOf('guidewire.edge.wsib_online_service.osbc.user_role') !== -1
           && authorities.indexOf('guidewire.edge.wsib_online_service.osbc.admin_role') !== -1)
      ) {
          return 'HYBRID';
      }
      if (
          authorities.indexOf('guidewire.edge.wsib_online_service.osbc.admin_role') !== -1
          && authorities.indexOf('guidewire.edge.wsib_online_service.osbc.user_role') === -1
          && authorities.indexOf('guidewire.edge.wsib_online_service.osbc.admin_and_user_role') === -1
      ) {
          return 'ADMIN';
      }
      if (authorities.indexOf('guidewire.edge.wsib_online_service.osbc.user_role') !== -1
      && authorities.indexOf('guidewire.edge.wsib_online_service.osbc.admin_role') === -1
      && authorities.indexOf('guidewire.edge.wsib_online_service.osbc.admin_and_user_role') === -1
      ) {
          return 'USER';
      }
      return '';
  };

  static submittedDocuments = [];

  static previousRole = '';

  static previousRoleArr = '';

  static setMessageThread = (data) => {
      sessionStorage.setItem('messageThread', JSON.stringify(data));
  };

  static getMessageThread = () => {
      if (sessionStorage.messageThread) {
          return JSON.parse(sessionStorage.getItem('messageThread'));
      }

      return [];
  };

  static deleteAllCookies = () => {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i];
          const eqPos = cookie.indexOf('=');
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      }
  };

  static getGenratedTokenTime(tokenGeneratedTime) {
      const presentTime = new Date().getTime();
      const diff = presentTime - tokenGeneratedTime;
      try {
          return (Math.round(parseInt(diff) / 1000)) / 60;
      } catch (error) {
          console.log('error');
          return '';
      }
  }

  static publishTimeSpent(publishEvent, publishCategory, lang) {
      const timeSpent = Math.round(parseInt(publishCategory) / 1000);
      publish(publishEvent, {
          eventCategory: timeSpent,
          locale: timeSpent
      });
  }

  static publishBounceRateSpent(publishEvent, lang) {
      let bounceRate = 'Bounce_Rate';
      if (lang === 'fr') {
          bounceRate = 'Bounce_Rate_Fr';
      }
      publish(publishEvent, {
          eventCategory: bounceRate,
          locale: bounceRate
      });
  }

  /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return business link on the basis of key and language parameters and error code.
   */
  static businessLinkError(lang) {
      if (lang === 'fr') {
          return this.HandleAmpercend(Configuration.businessLinkError.fr);
      }
      return this.HandleAmpercend(Configuration.businessLinkError.en);
  }

  /**
   *
   * @param {string} key
   * @param {string} lang
   * @returns link
   * return business link on the basis of key and language parameters and error code.
   */
  static businessLinkGeneralError() {
      return this.HandleAmpercend(Configuration.businessLinkGeneralError);
  }

  static tombStoneData = null;

  static fetchClaimNumber() {
      const url = window.location.pathname.split('/');
      return url[url.length - 1];
  }

  static languageChanged = false;

  static fetchInsightKey() {
      return Configuration.ApplicationInsight;
  }

  static generateRandomId() {
      return `_${Math.random()
          .toString(36)
          .substr(2, 9)}`;
  }

  static getSubscriptionKey() {
      return Configuration.SubscriptionKey;
  }
  /**
     *
     * @param {string} key
     * @param {string} lang
     * @returns link
     * return to enrollment link on the basis of key and language parameters.
     */

  static dueOsbcLandingpageLinks(lang) {
      if (lang === 'fr') {
          return this.HandleAmpercend(Configuration.dueOsbcLandingpageLinks.fr);
      }
      return this.HandleAmpercend(Configuration.dueOsbcLandingpageLinks.en);
  }

  static osbcEnrollmentVariantFlow(lang) {
      if (lang === 'fr') {
          return this.HandleAmpercend(Configuration.osbcEnrollmentVariantFlow.fr);
      }
      return this.HandleAmpercend(Configuration.osbcEnrollmentVariantFlow.en);
  }

  static eform7EnrollmentVariantFlow(lang) {
      if (lang === 'fr') {
          return this.HandleAmpercend(Configuration.eform7EnrollmentVariantFlow.fr);
      }
      return this.HandleAmpercend(Configuration.eform7EnrollmentVariantFlow.en);
  }

  static getSessionTimeoutValue() {
      return Configuration.sessionTimeoutValue;
  }

  /**
     *
     * @param {string} key
     * @param {string} lang
     * @returns link
     * return to respective useful links on the basis of key and language parameters.
     */
  static usefulLinksFromLandingPage(key, lang) {
      if (lang === 'fr') {
          return Configuration.contentFrench[key];
      }
      return Configuration.contentEnglish[key];
  }

  /**
     *
     * @param {string} key
     * @param {string} lang
     * @returns link
     *
     * return to enrollment link on the basis of key and language parameters.
     */
  static dueOsbcBusinessHomeLink(lang) {
      if (lang === 'fr') {
          return this.HandleAmpercend(Configuration.dueOsbcBusinessHomeLink.fr);
      }
      return this.HandleAmpercend(Configuration.dueOsbcBusinessHomeLink.en);
  }

  // return to account selection screen or account entry screen enrollosbc from claims signup
  static dueOsbcLandingpageClaimSignupLink(lang) {
      if (lang === 'fr') {
          return this.HandleAmpercend(Configuration.dueOsbcLandingpageClaimSignupLink.fr);
      }
      return this.HandleAmpercend(Configuration.dueOsbcLandingpageClaimSignupLink.en);
  }

  // function to fetch the Account Number
  static fetchAccountNumber() {
      const url = window.location.pathname.split('/');
      return url[url.length - 1];
  }

  static getAccountNumber() {
      const url = window.location.pathname.split('/');
      console.log('account number in util...', url[url.length - 2]);
      return url[url.length - 2];
  }

  static publishAccountTimeSpent(publishEvent, publishCategory, diff, lang = 'en') {
      const timeSpent = Math.round(parseInt(diff) / 1000);
      publish(publishEvent, {
          eventCategory: publishCategory,
          locale: timeSpent
      });
  }

  static getAccountBounceEventCategory(pageName) {
      const eventCategories = {
          'account-document-upload': 'DUE_AccountDoc_Bounce',
          'unified-landing-screen': 'DUE_BusServices_Bounce',
          'account-send-message': 'DUE_SendMessage_Bounce',
          'account-mail': 'DUE_Mail_Bounce',
          'account-helpful-link': 'DUE_HelpfulLinks_Bounce',
          'account-faq': 'DUE_FAQ_Bounce',
          'unnumbered-document-upload': 'DUE_UnNumbered_Bounce'
      };
      return eventCategories[pageName] || '';
  }

  static getSessionTimeoutEventCategory(pageName) {
      const eventCategories = {
          'account-document-upload': 'DUE_AccountDoc_EDUSysError60secs',
          'unified-landing-screen': 'DUE_BusServices_EDUSysError60secs',
          'account-send-message': 'DUE_SendMessage_EDUSysError60secs',
          'account-mail': 'DUE_Mail_EDUSysError60secs',
          'account-helpful-link': 'DUE_HelpfulLinks_EDUSysError60secs',
          'account-faq': 'DUE_FAQ_EDUSysError60secs',
          'unnumbered-document-upload': 'DUE_UN_EDUSysError60secs'
      };
      return eventCategories[pageName] || '';
  }

  static getlogoutInvokedEventCategory(pageName) {
      const eventCategories = {
          'account-document-upload': 'DUE_AccountDoc_EDUSysErrorTimeout',
          'unified-landing-screen': 'DUE_BusServices_EDUSysErrorTimeout',
          'account-send-message': 'DUE_SendMessage_EDUSysErrorTimeout',
          'account-mail': 'DUE_Mail_EDUSysErrorTimeout',
          'account-helpful-link': 'DUE_HelpfulLinks_EDUSysErrorTimeout',
          'account-faq': 'DUE_FAQ_EDUSysErrorTimeout',
          'unnumbered-document-upload': 'DUE_UN_EDUSysErrorTimeout'
      };
      return eventCategories[pageName] || '';
  }

  static publishAccountBounceRateSpent(publishEvent, publishCategory, lang) {
      publish(publishEvent, {
          eventCategory: this.fetchGaTag(publishCategory, lang),
          locale: this.fetchGaTag(publishCategory, lang)
      });
  }

  static getServiceName(key) {
        console.log(key);
        console.log(Configuration.serviceNames);
        console.log(Configuration.serviceNames[key]);
        return Configuration.serviceNames[key];
  }
}
