/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/extensions */
/* eslint-disable import/first */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
/* eslint-disable max-len */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable no-duplicate-case */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useContext,
    useState,
    useEffect,
    useCallback,
    useMemo,
    Suspense
} from 'react';
import {
    Switch, Route, Redirect, useParams
} from 'react-router-dom';
import _ from 'lodash';
// Imported First to allow Jutro styles to be overridden
import './App.scss';
import { setComponentMapOverrides } from '@jutro/uiconfig';
import {
    Main,
    Icon,
    Chevron,
    ModalNextProvider,
    Button
} from '@jutro/components';
import { Table, TableColumn } from '@jutro/datatable';
import { TranslatorContext, LocaleService } from '@jutro/locale';
import { NavRoutes } from '@jutro/router';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { publish } from '@jutro/events';
import {
    DynamicRoute, GrantedAuthorities, withAuthContextProvider, useAuthentication
} from 'gw-digital-auth-react';
import vmTranslator, { messages as commonMessages } from 'gw-platform-translations';
import { AccurateBreakpointPropagation } from 'gw-jutro-adapters-react';
import { ViewModelServiceFactory } from 'gw-portals-viewmodel-js';
import { LoginCapabilityComponentMap, LoginCapabilityComponents } from 'gw-portals-login-react';
import { UsersProfile } from 'gw-capability-profileinfo-react';
import { DependencyProvider } from 'gw-portals-dependency-react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import {
    platformComponentMap,
    platformComponents,
    ImageComponent,
} from 'gw-components-platform-react';
import { ErrorBoundary } from 'gw-portals-error-react';
// import { filterCapabilityRoutes } from 'gw-portals-config-js';
import { routes } from './App.routes.metadata.json5';
// const custom component LandingPage
const LandingPage = React.lazy(() => import('../pages/LandingPage/LandingPage'));
// const custom message file for App component.
import messages from './App.messages';
// imposrt custom component SessionTimeoutModal
const SessionTimeoutModal = React.lazy(() => import('../wsib/common/session-timeout-modal/session-timeout-modal'));
// imposrt custom component SessionTimeoutService
import SessionTimeoutService from '../wsib/services/SessionTimeoutService';
// const custom component ClaimSummaryHome
const ClaimSummaryHome = React.lazy(() => import('../wsib/claim-dashboard/pages/claim-summary-home/claim-summary-home'));
// const {custom component SessionExpiredHome
import SessionExpiredHome from '../wsib/common/pages/session-expired-home';

const Document = React.lazy(() => import('../wsib/claim-details/document/page/document'));
// const {custom component Header
const Header = React.lazy(() => import('../wsib/common/header/header'));
// const {custom= React.lazy(() => import(onent Footer
const Footer = React.lazy(() => import('../wsib/common/footer/footer'));
// const {custom component ClaimOverviewHome
const ClaimOverviewHome = React.lazy(() => import('../wsib/claim-details/calim-overview/page/claim-overview-home'));
// const {custom component BenefitPaymentHome
const BenefitPaymentHome = React.lazy(() => import('../wsib/claim-details/benefit-payment/page/benefit-payment-home'));
// const {custom component ViewCLaimListHome
const ViewCLaimListHome = React.lazy(() => import('../wsib/claim-dashboard/pages/view-claim-list-home/view-claim-list-home'));
// const {custom loader coponent MakePaymentPage
import { LoaderComponentComponentMap, LoaderComponentComponents } from '../wsib/common/loader/page/index';
// const {custom component Logout
const Logout = React.lazy(() => import('../wsib/common/logout/logout'));
// const {UtilService
import UtilService from '../wsib/services/UtilService';
// const {custom component LeftNav
const Faq = React.lazy(() => import('../wsib/common/faq/faq'));
// const {custom component HelpfulLinks
const HelpfulLink = React.lazy(() => import('../wsib/common/helpful-links/helpful-links'));
// const {custom component HealthCareHome
const HealthCareHome = React.lazy(() => import('../wsib/claim-details/healthcare-benefits/page/healthcare-home'));
// const {custom component appeals
const AppealsHome = React.lazy(() => import('../wsib/claim-details/appeals/page/appeals-home'));
// const {custom component Login
import Login from '../wsib/login/login';
// const {custom component MailHome
const MailHome = React.lazy(() => import('../wsib/claim-details/mail/page/mail-home'));
// const {custom component Send Message
const SendMessage = React.lazy(() => import('../wsib/claim-details/messaging/send-message/send-message'));
// const {custom context AppContext
import AppContext from '../wsib/app-context/app-context';
// const {custom component MailDetail
const MailDetail = React.lazy(() => import('../wsib/claim-details/mail/mail-detail/mail-detail'));
// const {IdpCallbackComponent to handle b2c error
const IdpCallbackComponent = React.lazy(() => import('../wsib/common/IdpCallbackComponent/IdpCallbackComponent'));
const IdpSystemErrorHome = React.lazy(() => import('../wsib/common/pages/idp-system-error-home'));
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../wsib/common/application-insight/application-insight.jsx';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Accountdocumentupload = React.lazy(() => import('../account/common/pages/account-document-upload/account-document-upload'));
const UnnumberedAccountDocumentUpload = React.lazy(() => import('../account/pages/unnumbered-document-upload-page'));
const AccountFaq = React.lazy(() => import('../account/common/account-faq/account-faq'));
const AccountHelpfulLlinks = React.lazy(() => import('../account/common/account-helpful-links/account-helpful-links'));
const AccountLandingPage = React.lazy(() => import('../account/unified-landing-page/landing-page/unified-landing-page'));
const AccountSendMessage = React.lazy(() => import('../account/messaging/send-message/account-send-message'));
const AccountMailDetail = React.lazy(() => import('../account/account-mail/account-mail-detail/account-mail-detail'));
const AccountMailHome = React.lazy(() => import('../account/account-mail/page/account-mail-home'));
const ViewReportsPage = React.lazy(() => import('../Reports/reports-dashboard/pages/viewreports-landing-page.jsx'));
const ExposureReports = React.lazy(() => import('../Reports/reports-dashboard/exposure-reports/exposure-reports.jsx'));
const FatalityReports = React.lazy(() => import('../Reports/reports-dashboard/fatality-reports/fatality-reports.jsx'));
const InjuryReports = React.lazy(() => import('../Reports/reports-dashboard/injury-reports/injury-reports.jsx'));
const LeftNavigation = React.lazy(() => import('../common/navigation/left-navigation.jsx'));
const AppTombstone = React.lazy(() => import('../common/tombstone/tombstone.jsx'));
const ReportHelpfulLinks = React.lazy(() => import('../Reports/dynamic-intake/common/report-helpful-links/report-helpful-links.js'));
const ReportFaq = React.lazy(() => import('../Reports/dynamic-intake/common/report-faq/report-faq.js'));

const DynamicIntakeReport = React.lazy(() => import('../Reports/dynamic-intake/pages/dynamic-intake-report'));

const { capabilitiesConfig } = appConfig;
const componentMap = {
    document: Document,
    dynamicIntakeReport: DynamicIntakeReport,
    sessionTimeout: SessionTimeoutModal,
    claimSummaryHome: ClaimSummaryHome,
    claimOverviewHome: ClaimOverviewHome,
    viewCLaimListHome: ViewCLaimListHome,
    benefitPaymentHome: BenefitPaymentHome,
    faq: Faq,
    helpfulLink: HelpfulLink,
    healthCareHome: HealthCareHome,
    appealsHome: AppealsHome,
    mailHome: MailHome,
    landingpage: LandingPage,
    usersprofile: UsersProfile,
    sendMessage: SendMessage,
    mailDetail: MailDetail,
    accountMailDetail: AccountMailDetail,
    accountdocumentupload: Accountdocumentupload,
    accountLandingScreen: AccountLandingPage,
    accountSendMessage: AccountSendMessage,
    accountMailHome: AccountMailHome,
    UnnumberedAccountDocumentUpload: UnnumberedAccountDocumentUpload,
    accountFaq: AccountFaq,
    accountHelpfulLinks: AccountHelpfulLlinks,
    ViewReportsPage: ViewReportsPage,
    InjuryReports: InjuryReports,
    ExposureReports: ExposureReports,
    FatalityReports: FatalityReports,
    ReportHelpfulLinks: ReportHelpfulLinks,
    ReportFaq: ReportFaq
};
const shouldRouteToSignUp = (authorities) => {
    const accountAuthorities = authorities.filter((authority) => authority.authorityType === 'WSIB_ONLINE_SERVICE'
        || authority.authorityType === 'ACCOUNT');
    if (accountAuthorities.length === 0) {
        if (localStorage.getItem('unNumberedDocURL') !== null && localStorage.getItem('unNumberedDocURL').includes('/account-management/unnumbered-document-upload')) {
            localStorage.removeItem('unNumberedDocURL');
            window.location.href = `/account-management/unnumbered-document-upload?lang=${LocaleService.getStoredLanguage()}`;
        } else {
            window.location.href = UtilService.businessLink(LocaleService.getCurrentLanguage());
        }
    }
    console.log(window.performance);
    if (window.performance) {
        const timeSincePageLoad = Math.round(performance.now());
        publish('timing_complete', {
            eventCategory: timeSincePageLoad,
            locale: timeSincePageLoad
        });
    }
    UtilService.previousRole = UtilService.getUserRole();
    UtilService.previousRoleArr = document.getElementsByTagName('html')[0].getAttribute('accesToken');
    return null;
};
setComponentMapOverrides(
    {
        ...platformComponentMap,
        ...LoginCapabilityComponentMap,
        ...LoaderComponentComponentMap,
        Icon: { component: 'Icon' },
        Table: { component: 'Table' },
        TableColumn: { component: 'TableColumn' },
        Chevron: { component: 'Chevron' },
        img: { component: 'img' },
    },
    {
        ...platformComponents,
        ...LoginCapabilityComponents,
        ...LoaderComponentComponents,
        Icon,
        Table,
        TableColumn,
        Chevron,
        img: ImageComponent,
    }
);
// (Router) Title is required and used for (Browser) title
// in a format of "${title} - ${appName}". In order to show
// the application name only, it needs to be an empty value.
// const routesWithTitle = filterCapabilityRoutes(capabilitiesConfig, routes);
const routesWithoutTitle = routes.map((route) => {
    return {
        ...route,
        title: ''
    };
});
function App(props) {
    const auth = useAuthentication();
    const translator = useContext(TranslatorContext);
    const [viewModelService, setViewModelService] = useState(undefined);
    const [showLeftNav, setshowLeftNav] = useState(true);
    const [aodaPageTitle, setAodaPageTitle] = useState(translator(messages.claimsPageTitle));
    const [accountLeftNav, setAccountLeftNav] = useState(false);
    const setDecodedEncodedKeywords = useCallback((value) => {
        localStorage.setItem('setDecodedEncodedKeywords', value);
    }, []);
    const setSpecialCharReplaceWithUniCodeKeywords = useCallback((value) => {
        localStorage.setItem('setSpecialCharReplaceWithUniCodeKeywords', value);
    }, []);
    const getDecodedEncodedKeywords = useCallback(() => {
        return localStorage.getItem('setDecodedEncodedKeywords');
    }, []);
    const getSpecialCharReplaceWithUniCodeKeywords = useCallback(() => {
        return localStorage.getItem('setSpecialCharReplaceWithUniCodeKeywords');
    }, []);

    const userSettings = {
        setDecodedEncodedKeywords,
        setSpecialCharReplaceWithUniCodeKeywords,
        getDecodedEncodedKeywords,
        getSpecialCharReplaceWithUniCodeKeywords
    };
    useEffect(() => {
        const translatorFn = vmTranslator(translator);
        import(
            // eslint-disable-next-line import/no-unresolved
            'product-metadata'
        ).then((productMetadata) => {
            const { default: result } = productMetadata;
            setViewModelService(
                ViewModelServiceFactory.getViewModelService(result, translatorFn)
            );
        });
    }, [translator]);
    useEffect(() => {
        const currentLang = LocaleService.getStoredLocale();
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const lang = params.get('lang');
        if (localStorage.getItem('unNumberedDocURL') !== null && localStorage.getItem('unNumberedDocURL').includes('/account-management/unnumbered-document-upload') && window.location.pathname === '/') {
            window.location.href = '/account-management/unnumbered-document-upload';
            localStorage.removeItem('unNumberedDocURL');
        }
        if (lang && window.location.pathname.includes('/account-management/unnumbered-document-upload')) {
            localStorage.setItem('unNumberedDocURL', window.location.pathname);
        }
        if (lang === 'fr' && lang !== currentLang) {
            LocaleService.setCurrentLocale(lang);
            LocaleService.setCurrentLanguage(lang);
            window.location.reload();
        }
        if (lang === 'en' && lang !== currentLang) {
            LocaleService.setCurrentLocale(lang);
            LocaleService.setCurrentLanguage(lang);
            window.location.reload();
        }
    }, []);

    const showLeftNavfn = () => {
        const pageName = window?.location?.pathname?.split('/')[2];
        if (['account-document-upload', 'account-send-message', 'account-mail', 'account-mailDetail', 'account-helpful-link', 'account-faq'].indexOf(pageName) >= 0) {
            setAccountLeftNav(true);
        } else {
            setAccountLeftNav(false);
        }
        if (['/account-management/claims', '/account-management/dynamic-intake', '/account-management/session-expired', '/account-management/view-claim-list-home', '/account-management/unified-landing-screen', '/account-management/unnumbered-document-upload', `/account-management/${window?.location?.pathname?.split('/')[0]}`, `/account-management/dynamic-intake/${window?.location?.pathname?.split('/')[3]}/${window?.location?.pathname?.split('/')[4]}`, `/account-management/dynamic-intake/${window?.location?.pathname?.split('/')[3]}`].indexOf(window.location.pathname) !== -1) {
        // if (['/account-management/claims', '/account-management/session-expired', '/account-management/view-claim-list-home', `/account-management/account-document-upload/${props?.location?.pathname?.split('/')[2]}`, '/account-management/unified-landing-screen', '/account-management/unnumbered-document-upload'].indexOf(window.location.pathname) !== -1) {
            setshowLeftNav(true);
        } else {
            setshowLeftNav(false);
        }
    };
    /* hide left nav component if current screen is not home screen */
    const leftNav = useMemo(() => {
        let defaultScreen = 0;
        const elementRef = document.getElementById('pageTitle');
        let pathName = '';
        if (window.location.pathname.includes('/account-management/claims')) {
            pathName = '/account-management/claims';
            defaultScreen = 0;
            document.title = translator(messages.claimsPageTitle);
            setAodaPageTitle(translator(messages.claimsPageTitle));
        } else if (window.location.pathname.includes('/account-management/view-claim-list-home')) {
            pathName = '/account-management/view-claim-list-home';
            defaultScreen = 0;
            document.title = translator(messages.viewClaimListPageTitle);
            setAodaPageTitle(translator(messages.viewClaimListPageTitle));
        } else if (window.location.pathname.includes('/account-management/claimOverview')) {
            pathName = '/account-management/claimOverview';
            defaultScreen = 0;
            document.title = translator(messages.claimOverviewPageTitle);
            setAodaPageTitle(translator(messages.claimOverviewPageTitle));
        } else if (window.location.pathname.includes('/account-management/benefitPayment')) {
            pathName = '/account-management/benefitPayment';
            defaultScreen = 0;
            document.title = translator(messages.benefitPaymentPageTitle);
            setAodaPageTitle(translator(messages.benefitPaymentPageTitle));
        } else if (window.location.pathname.includes('/account-management/healthCare')) {
            pathName = '/account-management/healthCare';
            defaultScreen = 0;
            document.title = translator(messages.healthCarePageTitle);
            setAodaPageTitle(translator(messages.healthCarePageTitle));
        } else if (window.location.pathname.includes('/account-management/appeals')) {
            pathName = '/account-management/appeals';
            defaultScreen = 0;
            document.title = translator(messages.appealsPageTitle);
            setAodaPageTitle(translator(messages.appealsPageTitle));
        } else if (window.location.pathname.includes('/account-management/documentUpload')) {
            pathName = '/account-management/documentUpload';
            defaultScreen = 0;
            document.title = translator(messages.claimDocumentsPageTitle);
            setAodaPageTitle(translator(messages.claimDocumentsPageTitle));
        } else if (window.location.pathname.includes('/account-management/sendmessage')) {
            pathName = '/account-management/sendmessage';
            defaultScreen = 0;
            document.title = translator(messages.sendUsMessagePageTitle);
            setAodaPageTitle(translator(messages.sendUsMessagePageTitle));
        } else if (window.location.pathname.includes('/account-management/mail')) {
            pathName = '/account-management/mail';
            setAodaPageTitle(translator(messages.mailPageTitle));
            defaultScreen = 0;
            document.title = translator(messages.mailPageTitle);
        } else if (window.location.pathname.includes('/account-management/helpful-link')) {
            pathName = '/account-management/helpful-link';
            defaultScreen = 0;
            document.title = translator(messages.helpFullLinkPageTitle);
            setAodaPageTitle(translator(messages.helpFullLinkPageTitle));
        } else if (window.location.pathname.includes('/account-management/faq')) {
            pathName = '/account-management/faq';
            defaultScreen = 0;
            document.title = translator(messages.faqPageTitle);
            setAodaPageTitle(translator(messages.faqPageTitle));
        } else if (window.location.pathname.includes('/account-management/unified-landing-screen')) {
            pathName = '/account-management/unified-landing-screen';
            defaultScreen = 0;
            document.title = translator(messages.unifiedLandingPageTitle);
            setAodaPageTitle(translator(messages.unifiedLandingPageTitle));
        } else if (window.location.pathname.includes('/account-management/unnumbered-document-upload')) {
            pathName = '/account-management/unnumbered-document-upload';
            defaultScreen = 0;
            document.title = translator(messages.unNumberedDocUploadTitle);
            setAodaPageTitle(translator(messages.unNumberedDocUploadTitle));
        } else if (window.location.pathname.includes('/account-management/account-document-upload')) {
            pathName = '/account-management/account-document-upload';
            defaultScreen = 0;
            document.title = translator(messages.accountDocumentsPageTitle);
            setAodaPageTitle(translator(messages.accountDocumentsPageTitle));
        } else if (window.location.pathname.includes('/account-management/account-send-message')) {
            pathName = '/account-management/account-send-message';
            defaultScreen = 0;
            document.title = translator(messages.accountSendUsMessagePageTitle);
            setAodaPageTitle(translator(messages.accountSendUsMessagePageTitle));
        } else if (window.location.pathname.includes('/account-management/account-mail')) {
            pathName = '/account-management/account-mail';
            setAodaPageTitle(translator(messages.accountMailPageTitle));
            defaultScreen = 0;
            document.title = translator(messages.accountMailPageTitle);
        } else if (window.location.pathname.includes('/account-management/account-helpful-link')) {
            pathName = '/account-management/account-helpful-link';
            defaultScreen = 0;
            document.title = translator(messages.accountHelpFullLinkPageTitle);
            setAodaPageTitle(translator(messages.accountHelpFullLinkPageTitle));
        } else if (window.location.pathname.includes('/account-management/account-faq')) {
            pathName = '/account-management/account-faq';
            defaultScreen = 0;
            document.title = translator(messages.accountFaqPageTitle);
            setAodaPageTitle(translator(messages.accountFaqPageTitle));
        } else if (window.location.pathname.includes('/account-management/view-reports-home-page')) {
            pathName = '/account-management/view-reports-home-page';
            defaultScreen = 0;
            document.title = translator(messages.viewReportsPageTitle);
            setAodaPageTitle(translator(messages.viewReportsPageTitle));
            sessionStorage.setItem('appName', 'Reports');
        } else if (window.location.pathname.includes('/account-management/illness-injury-home-page')) {
            pathName = '/account-management/illness-injury-home-page';
            defaultScreen = 0;
            document.title = translator(messages.injuryIllnessPageTitle);
            setAodaPageTitle(translator(messages.injuryIllnessPageTitle));
            sessionStorage.setItem('appName', 'Reports');
        } else if (window.location.pathname.includes('/account-management/exposure-home-page')) {
            pathName = '/account-management/exposure-home-page';
            defaultScreen = 0;
            document.title = translator(messages.exposurePageTitle);
            setAodaPageTitle(translator(messages.exposurePageTitle));
            sessionStorage.setItem('appName', 'Reports');
        } else if (window.location.pathname.includes('/account-management/fatality-home-page')) {
            pathName = '/account-management/fatality-home-page';
            defaultScreen = 0;
            document.title = translator(messages.fatalityPageTitle);
            setAodaPageTitle(translator(messages.fatalityPageTitle));
            sessionStorage.setItem('appName', 'Reports');
        } else if (window.location.pathname.includes('/account-management/report-helpful-link')) {
            pathName = '/account-management/report-helpful-link';
            defaultScreen = 0;
            document.title = translator(messages.helpfulLinkPageTitle);
            setAodaPageTitle(translator(messages.helpfulLinkPageTitle));
            sessionStorage.setItem('appName', 'Reports');
        } else if (window.location.pathname.includes('/account-management/report-faq')) {
            pathName = '/account-management/report-faq';
            defaultScreen = 0;
            document.title = translator(messages.ReportFaqPageTitle);
            setAodaPageTitle(translator(messages.ReportFaqPageTitle));
            sessionStorage.setItem('appName', 'Reports');
        } else {
            defaultScreen = 1;
            document.title = translator(messages.defaultPageTittle);
            setAodaPageTitle(translator(messages.defaultPageTittle));
        }
        if (elementRef) {
            elementRef.focus();
        }
        if (defaultScreen === 0) {
            window.scrollTo(0, 0);
        }
        showLeftNavfn();
        window.onbeforeunload = function () {
            const pageName = window?.location?.pathname?.split('/')[2];
            if (UtilService.languageChanged === false) {
                if (['account-document-upload', 'account-send-message', 'account-mail', 'account-helpful-link', 'account-faq', 'unified-landing-screen', 'unnumbered-document-upload'].indexOf(pageName) >= 0) {
                    const eventCategory = UtilService.getAccountBounceEventCategory(pageName);
                    UtilService.publishAccountBounceRateSpent('Bounce', eventCategory, LocaleService.getStoredLanguage());
                } else {
                    UtilService.publishBounceRateSpent(pathName,
                        LocaleService.getStoredLanguage());
                }
            }
        };
    }, [window.location.pathname]);

    const handleError = useCallback(() => {
        return ModalNextProvider.showAlert({
            title: messages.error,
            message: messages.errorMessage,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(_.noop);
    }, []);

    if (auth.isLoggedIn) {
        const inactivityTimer = SessionTimeoutService();
        inactivityTimer.resetInactivityTimer();
    }

    const skipcomponent = document.querySelector('.jut__SkipNav__skipNav');
    if (skipcomponent) {
        skipcomponent.remove();
    }

    return (
        <Suspense fallback={<p id="pageTitle" className="pageTittle" role="alert">{aodaPageTitle}</p>
        }
        >
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}>
                <AppContext.Provider value={userSettings}>
                    {/* <header>
                        <p id="pageTitle" className="pageTittle" role="alert">{aodaPageTitle}</p>
                    </header> */}
                    <React.Fragment>
                        <AccurateBreakpointPropagation>
                            <DependencyProvider
                                value={{
                                    SessionTimeoutService: SessionTimeoutService
                                }}
                            >
                                <ErrorBoundary onError={handleError}>
                                    <ViewModelServiceContext.Provider value={viewModelService}>
                                        <ModalNextProvider />
                                        <Switch>


                                            <Route exact path="/login-page" component={Login} />
                                            <Route exact path="/session-expired" component={SessionExpiredHome} />
                                            <Route path="/idp-system-error-home" component={IdpSystemErrorHome} />
                                            <Route path="/osbcLogout" component={Logout} />
                                            <Route path="/osbc/uaa/login/callback/microsoftb2c_osbc" component={IdpCallbackComponent} />
                                            <DynamicRoute path="/unnumbered-document-upload">
                                                <Header />
                                                <Main>
                                                    <NavRoutes
                                                        resolveComponentMap={componentMap}
                                                        routes={routesWithoutTitle}
                                                    />
                                                </Main>
                                                <Footer />
                                            </DynamicRoute>
                                            <DynamicRoute path="/">
                                                <GrantedAuthorities
                                                    checkRenderBasedOnAuthorities={shouldRouteToSignUp}
                                                >

                                                    <Header />
                                                    {
                                                        (showLeftNav) ? (
                                                            <Main>

                                                                <NavRoutes
                                                                    resolveComponentMap={componentMap}
                                                                    routes={routesWithoutTitle}
                                                                />
                                                            </Main>

                                                        ) : (
                                                            <Main>
                                                                <span tabIndex="-1" id="bodySection" className="osbcMainContainer">
                                                                    <AppTombstone />
                                                                </span>
                                                                <div className="osbcMainContainer leftNavMainClass">
                                                                    <LeftNavigation />
                                                                    <NavRoutes
                                                                        resolveComponentMap={componentMap}
                                                                        routes={routesWithoutTitle}
                                                                    />
                                                                </div>
                                                            </Main>

                                                        )

                                                    }
                                                    <Footer />
                                                </GrantedAuthorities>
                                            </DynamicRoute>
                                        </Switch>
                                    </ViewModelServiceContext.Provider>
                                </ErrorBoundary>
                            </DependencyProvider>
                        </AccurateBreakpointPropagation>
                    </React.Fragment>
                </AppContext.Provider>
            </GoogleReCaptchaProvider>
        </Suspense>
    );
}

export default withAuthContextProvider()(withAITracking(reactPlugin, App));
