// The Auth API is based on error objects rather than strings
/* eslint-disable no-throw-literal, prefer-promise-reject-errors */
/* eslint-disable max-len */
import _ from 'lodash';
import styles from './IframeUtil.module.scss';
import ERRORS from '../../AuthErrors';

// time before the iframe is expected to be loaded (in ms)
// DUE temporary increase the this timeout to 40 secs // TODO to revert back
// const TIMEOUT_BEFORE_IFRAME_REJECT = 20000;

/**
 * OSBC/DUE CHANGE
 * increased Timeout Value
 * 
 * */
const TIMEOUT_BEFORE_IFRAME_REJECT = 40000; // Added as part of ui-osbc

function getIframeData(iframeEl) {
    const iframeWin = iframeEl.contentWindow || iframeEl;
    const { href } = iframeWin.location;
    const { searchParams } = new URL(href);
    const iframeParams = _.fromPairs(Array.from(searchParams.entries()));

    const iframeData = {
        iframeDoc: iframeEl.contentDocument || iframeEl.contentWindow.document,
        iframeWrapper: iframeEl.parentNode,
        iframeLocation: href,
        iframeParams
    };
    return iframeData;
}

/**
 * @typedef {Object} IFrameRequest
 * @property {String} src the source of the iframe
 * @property {String} [expectedSrcPartOnLoad] the expected path if the user is logged in already
 * @property {String} [failureRedirectUrl] the URL to which the user should be redirected
 *                                          upon failure
 */

/**
 * @typedef {Object} IFrameResponse
 * @property {String} [error] describes why Promise is rejected
 * @property {Document} [iframeDoc] the iframe document
 */

function iframeLoadPromiseHandler(iframeEl, resolve, reject) {
    try {
        const iframeData = getIframeData(iframeEl);
        // i.e. and firefox dont throw an error for some auth iframes.
        // This is forcing the same behaviour as chrome
        if (iframeData.iframeLocation.includes('about:blank')) {
            throw new DOMException();
        }
        resolve(iframeData);
    } catch (e) {
        if (e instanceof DOMException || _.includes(e.message, 'Access is denied.')) {
            reject({
                authorizeWithoutIFrame: true
            });
            return;
        }
        // eslint-disable-next-line no-console
        console.error(e);
        reject();
    }
}

function checkIframeContent(iframeData, options) {
    const { expectedSrcPartOnLoad, failureRedirectUrl } = options;
    if (!iframeData.iframeDoc.querySelector('meta[content="Cloud Foundry"],meta[content="Guidewire"]')) {
        // not redirected to a UAA page so must be an external IDP Page
        throw ({
            fullPageRedirectRequired: iframeData.iframeDoc.URL
        });
    }
    if (expectedSrcPartOnLoad) {
        // check if the src meets expectations (e.g. iframe was redirected)
        if (!iframeData.iframeLocation.includes(expectedSrcPartOnLoad)) {
            throw ({ error: ERRORS.expectedSrcPartOnLoad });
        }
        if (iframeData.iframeLocation.includes('error')) {
            throw ({
                fullPageRedirectRequired: failureRedirectUrl
            });
        }
    }
    return iframeData;
}

function iframeErrorPromiseHandler(reject) {
    reject({ error: ERRORS.iframeLoadError });
}

/**
 * Loads a new iframe.
 *
 * @param {IFrameRequest} request the iframe request
 * @returns {Promise<IFrameResponse>}
 */
function loadIframe({ src }) {
    const iframeEl = document.createElement('iframe');
    const iframeWrapper = document.createElement('div');
    iframeWrapper.classList.add(styles.hiddenIframe);
    iframeWrapper.appendChild(iframeEl);

    let iframeOnLoadListener;
    let iframeOnErrorListener;

    const iframePromise = new Promise(
        (resolve, reject) => {
            iframeOnLoadListener = () => iframeLoadPromiseHandler(iframeEl, resolve, reject);
            iframeOnErrorListener = () => iframeErrorPromiseHandler(reject);
            // on iframe changes the state
            iframeEl.addEventListener('load', iframeOnLoadListener);
            iframeEl.addEventListener('error', iframeOnErrorListener);
        }
    );

    // Promise with timeout rejections
    let timeoutId;
    const loadingTimeout = new Promise(
        (_resolve, reject) => {
            timeoutId = setTimeout(
                () =>{ 
                    console.log("ERRORS.loadTimeout", ERRORS.loadTimeout);
                    reject({ error: ERRORS.loadTimeout })
                },
                TIMEOUT_BEFORE_IFRAME_REJECT
            );
        }
    ).finally(() => {
        clearTimeout(timeoutId);
    });

    // start loading
    iframeEl.src = src;
    document.body.appendChild(iframeWrapper);

    // load with timeout
    const loadingPromise = Promise.race([
        iframePromise,
        loadingTimeout
    ]).finally(() => {
        // cleanup
        iframeEl.removeEventListener('load', iframeOnLoadListener);
        iframeEl.removeEventListener('error', iframeOnErrorListener);
        document.body.removeChild(iframeWrapper);// remove appended nodes
    });

    return loadingPromise;
}

// EXPORT
export default {
    loadIframe,
    checkIframeContent
};
